// It is not as important as necessary though
.disabledPag,
.disabledPag a {
  cursor: default !important;
  opacity: .5 !important;
}

.pagination-line {
  background-color: $c-white;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top: 1px solid rgba(104, 103, 102, .1);
  margin: 20px 0;
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 12px;
  text-align: center;

  %pg-active {
    background-color: $c-bluegrey-dark;
    color: $c-white;
  }

  %pg-inner {
    @extend %pull-left;
    background-color: $c-white;
    border: 1px solid $c-header-btn-bg;
    border-left-width: 0;
    font-size: 11.9px;
    line-height: 20px;
    padding: 2px 10px;
    text-decoration: none;
  }

  ul {
    @include border-radius(2px);
    display: inline-block;
    margin-bottom: 0;
    margin-left: 0;
  }

  li {
    @extend %clear-float;
    display: inline-block;
    margin-left: 11px;

    a {
      @extend %clickable;
      @extend %pg-inner;
      border-width: 0;
      min-width: 12pt;

      &:hover {
        @extend %pg-active;
      }
    }

    span {
      color: $c-black;
      font-size: 14px;
      position: relative;
      top: 2px;
    }

    &:last-child,
    &:first-child {
      a,
      span {
        @include box-shadow(0);
        background: $c-bluegrey-dark;
        border: 0;
        color: $c-white;
        height: 21px;
        position: relative;
        width: 14px;
      }
    }

    &.active {
      a,
      span {
        @extend %pg-active;
      }
    }

    &.disabled {
      &:last-child,
      &:first-child {
        a,
        span {
          background-color: $c-pag-disabled-bg;
          color: $c-pag-disabled-fg;
          cursor: default;
          pointer-events: none;
        }
      }
    }
  }
}

.collection-pagination {
  margin-left: 23px !important;
}

%pagination-icon {
  font-size: 19px;
  position: relative;
  top: -2px;
}

.arango-pagination {

  li {
    &:first-child {
      margin-right: 20px;
    }

    &:last-child {
      margin-left: 30px;
    }
  }

  i.fa {
    @extend %pagination-icon;
  }
}

.pre-pagi,
.las-pagi {
  i.fa {
    @extend %pagination-icon;
  }
}

.pre-pagi {
  margin-right: -5px !important;
}
