div.gv-manage-button-container {
  margin-top: 10px;
}

legend.gv-inner {
  font-size: 16px;
}

input.gv-radio-button {
  display: block;
  margin-top: 3px;
  width: auto;
}
