.collection-row {
  background: rgba(0, 0, 0, .1);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  margin-bottom: -11px !important;

  &:hover {
    background: rgba(0, 0, 0, .1) !important;
  }

  input {
    margin-top: 0;
  }

  .collection-label {
    background-color: $c-bluegrey-dark;
    color: $c-white;
  }

  .collection-label-first {
    border-top-left-radius: 3px;
  }

  .collection-label-last {
    border-top-right-radius: 3px;
  }

  .pure-table-row {
    border-bottom: 1px solid rgba(255, 255, 255, 1) !important;

    &:hover {
      background: rgba(0, 0, 0, 0) !important;
    }
  }

  .collLabel {
    font-weight: 600;
    margin-left: 10px;
  }

  .collName {
    color: $c-bluegrey-dark;
    margin-left: 20px;
  }
}

.db-row {
  &:hover {
    cursor: pointer !important;
  }

  &.noAction {
    cursor: default !important;
  }
}
