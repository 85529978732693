// TODO Needs to be cleaned up it is not so !important as it looks...
.jsoneditor {
  background-color: $c-white !important;
  border: 0 solid $c-semi-transp !important;

  .jsoneditor-sort,
  .jsoneditor-transform,
  .jsoneditor-statusbar,
  .jsoneditor-repair {
    display: none;
  }

  .jsoneditor-outer.has-status-bar {
    padding: 35px 0 0px;
  }

  .jsoneditor-menu {
    background-color: $c-white !important;
    border-bottom: 1px solid $c-c2grey !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important;

    .jsoneditor-undo {
      background-position: -24px -120px;
    }

    .jsoneditor-redo {
      background-position: -48px -120px;
    }

    > button {
      border: 0 !important;
      color: $c-black;
      opacity: .4 !important;

      &:hover {
        background-color: $c-positive;
        color: $c-white !important;
        cursor: pointer;
        opacity: 1 !important;
      }

      &.jsoneditor-selected {
        background-color: $c-positive !important;
        color: $c-white !important;
        opacity: 1 !important;
      }

      &.jsoneditor-undo,
      &.jsoneditor-redo {
        opacity: 1 !important;
      }

      &.jsoneditor-format,
      &.jsoneditor-expand-all,
      &.jsoneditor-collapse-all,
      &.jsoneditor-compact {
        background-color: $c-black !important;
        filter: invert(100%);

        &:hover {
          background-color: $c-positive !important;
          color: $c-white !important;
          filter: invert(0%);
        }
      }
    }

    .jsoneditor-modes {
      button {
        border: 0 !important;
        color: $c-black;
        opacity: .4 !important;

        &:hover {
          background-color: $c-positive;
          color: $c-white !important;
          cursor: pointer;
          opacity: 1 !important;
        }

        &.jsoneditor-selected {
          background-color: $c-positive !important;
          color: $c-white !important;
          opacity: 1 !important;
        }
      }
    }
  }

  .jsoneditor-search {
    .jsoneditor-frame {
      border: 0 !important;
      margin-top: -1px !important;

      button {
        border-radius: 2px;

        &.jsoneditor-refresh {
          background-position: -96px -72px;
          margin-right: -10px;
          margin-top: 1px;
          width: 25px;
        }

        &.jsoneditor-next {
          margin-left: -10px;
        }
      }

      .refresh {
        background-position: -96px -73px;
        height: 22px;
        width: 25px;
      }

      input {
        @extend %inputs;
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 0;

        &:focus {
          border: 2px solid $c-positive !important;
        }
      }
    }

    .results {
      color: $c-white !important;
      margin-top: 3px !important;
    }
  }
}

.document-editor-extra {
  margin-top: 10px;
}

.document-editor {
  margin-top: -10px;
  width: 100%;
}

.jsoneditor-contextmenu {
  z-index: 999999999999;
}
