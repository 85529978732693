.arangoFoxxToolbarTop {
  background-color: transparent;
  border: 0;
  float: right;
  height: 62px;
  margin-top: -74px;
  width: auto;

  .pull-right {
    margin-top: 18px;
  }
}

.foxxesInner {
  p {
    padding: 10px;
  }

  .errorMessage {
    z-index: 2;
  }

  .collectionInfoTh {
    font-weight: 400;
  }
}
  
#foxxFilters {
  font-weight: 500 !important;
  opacity: .7;
  text-align: right;

  i {
    font-size: 11pt;
    margin-right: 5px;
    top: 0;
  }
}
