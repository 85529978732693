.dashboardDistribution {
  float: left;
  width: 270px;

  svg {
    height: 220px;
    width: 250px;
  }
}

