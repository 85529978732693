.collectionTh {
  @extend %font-family;
  font-size: 14px;
  font-weight: 400 !important;
  text-align: left;
  width: 20% !important;

  textarea {
    margin-top: 10px;
  }
}

.collectionInfoTh {
  min-width: 60px;
  text-align: left;
}

.addCollection {
  $inputwidth: 320px;

  table tr {
    border-bottom: 0 !important;
    height: 53px;
  }

  .icon_arangodb_info {
    margin-left: 20px !important;
    position: relative;
    top: 2px !important;
  }

  .accordion {
    margin-top: 10px;
  }

  .collectionThSec {
    width: $inputwidth !important;
  }

  .collectionTh {
    width: 96px;
  }

  .modalInput {
    width: $inputwidth;
  }

  .modalSelect {
    width: $inputwidth + 14px;
  }

  .accordion-toggle {
    width: $inputwidth + 137px !important;
  }
}

.change-collection {

  .tab-content {
    min-height: 230px;
  }

  input {
    width: 384px !important;
  }

  select {
    width: 398px !important;
  }
}

.show-collection {

  .tab-content {
    min-height: 200px;
  }
}

.collectionInfoTh2 {
  @extend %font-family;
  font-weight: 400 !important;
  text-align: left;
  width: 200px;
}

.collection-info-figures table {
  float: left;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  min-width: 200px;
  padding: 3px;
  text-align: left;
}

.figuresHeader {
  border-bottom: 1px solid  $c-c2grey;

  th {
    @extend %font-family;
    font-weight: 400 !important;
  }
}


#collectionIndexTable {
  margin-left: 0;
  width: 100%;
}

#infoTab,
#graphTab,
#collectionTab {
  border-bottom: 0;
  margin-bottom: 1px;
  padding-bottom: 0;
  padding-right: 10px;

}

#infoTab li,
#graphTab li,
#collectionTab li {
  float: right;

  &.disabled {
    a {
      cursor: not-allowed;
    }
  }
}

#infoTab a,
#graphTab a,
#collectionTab a {
  background-color: $c-bluegrey-dark;
  border-bottom: 1px solid $c-darker-grey;
  border-top-left-radius: 3px !important;
  border-top-right-radius: 3px !important;
  color: $c-white;
  font-size: 13px !important;
  height: 21px;
  margin-bottom: -1px;
  margin-right: 4px;
  padding: 2px 15px !important;
}

#infoTab .active > a,
#graphTab .active > a,
#collectionTab .active > a {
  background-color: $c-modal-header;
  border-color: $c-collection-tab-border-bottom $c-collection-tab-border-bottom transparent !important;
  color: $c-black;
}

#confirmCreateEdge {
  margin-left: 20px;
}

.collection-info-figures .icon_arangodb_info {
  position: relative !important;
  right: -4px !important;
}


