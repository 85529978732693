.document-info-div {
  min-height: 0;
}

.document-content-div {
  margin-top: 10px;
}

.document-info {

  background-color: $c-white;
  border-radius: 3px;
  margin-bottom: 8px;
  margin-top: -13px;
  min-height: 65px;
  padding: 5px;

  .document-info-container {
    clear: both;
    width: 100%;

    .document-inner-info-container {
      float: left;
      margin-left: 10px;
      margin-top: 5px;

      > .document-attribute {
        margin-bottom: 5px;
      }

      .document-attribute {
        @extend %clear-float;
        margin-right: 20px;

        div {
          float: left;
        }

        input {
          @extend %inputs;
          margin: 0;
          margin-top: -2px;
          margin-bottom: -4px;
          padding: 0;
          width: auto;
        }

        .fa {
          font-size: 16px;

          &:hover {
            cursor: pointer;
          }
        }

        .fa-edit {
          opacity: .7;
          &:hover {
            opaciy: 1;
            color: $c-positive;
          }
        }

        .fa-check {
          color: $c-positive;
          &:hover {
            color: $c-positive-hover;
          }
        }

        .fa-times {
          color: $c-negative;
          &:hover {
            color: $c-negative-hover;
          }
        }

      }
    }
  }

  .document-bold-font {
    font-family: droid sans mono, monospace, courier new, courier, sans-serif;
    font-size: 11pt;
    min-width: 55px;
  }

  .document-thin-font {
    color: $c-positive;
    font-family: droid sans mono, monospace, courier new, courier, sans-serif;
    font-size: 11pt;
  }

  .document-type-container {
    div {
      float: left;
    }

    #document-id {
      margin-left: 10px;
    }
  }
}

.document-link {
  &.unsaved {
    color: $c-warning;

    &:hover {
      cursor: default;
      text-decoration: none;
    }
  }
}

.document-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.jsoneditor {
  .tree {
    div {
      font-size: 11pt;
    }
  }

  .outer {
    .tree > table > tbody > tr:first-of-type {
      visibility: hidden;
    }
  }

}
