.arango-tabbar {
  height: 27px;
  width: 100%;

  button {
    background-color: $c-bluegrey-dark;
    border: 0;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    color: $c-white;
    float: right;
    font-weight: 100;
    height: 27px;
    margin-right: 5px;
    width: 82px;
  }

  button:first-child {
    margin-right: 10px;
  }

  .arango-active-tab {
    background-color: $c-white;
    border-bottom: 1px solid $c-white;
    border-left: 1px solid $c-c2grey;
    border-right: 1px solid $c-c2grey;
    border-top: 1px solid $c-c2grey;
    color: $c-black;
    height: 28px;
    margin-bottom: -1px;
  }
}
