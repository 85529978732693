.pure-table,
.arango-table {
  width: 100%;

  thead {
    background-color: $c-white;

    th {
      border-bottom: 1px solid $c-c2grey;
      font-weight: 500;
      height: 43px;
      padding-left: 18px;
      text-align: left;
    }
  }

  tbody {

    tr:nth-child(even) {
      background-color: $c-white;
    }

    tr:nth-child(odd) {
      background: $c-table-odd-color;
    }

    td {
      padding: 10px 18px;

      pre {
        background: none;
        border: 0;
        padding-left: 0px;
        padding-top: inherit;
        margin: 0px 0px 0px 0px;
      }
    }
  }

  table {
    border: 1px solid rgb(62, 74, 82);
    margin-left: 10px;
    width: 100%;

    tbody {
      tr:nth-child(even) {
        background-color: $c-white;
      }

      tr:nth-child(odd) {
        background: rgba(0, 0, 0, 0);;
      }
    }
  }
}

.pure-table {
  &.pure-table-inner {
    margin: 0;
    padding: 0;
    border: 0;

    thead, tbody {
      width: 100%;
      display: table;
      background: $c-bluegrey-dark !important;
      border-radius: 5px;
      color: white;
    }

    th {
      padding: 0;
      border: 0;
      height: 20px;
    }

    tr {
      height: 10px;
      background: rgba(0,0,0,0) !important;
      padding: 0;
      border: 0;

      td {
        vertical-align: baseline;
        height: 10px;
        padding: 0;
        border: 0;

        ul {
          margin: 0;
          padding: 0;

          li {
            list-style-type: none;
            margin: 0;
          }
        }

        span {
          margin-left: 10px;
        }
      }
    }
  }
}
